<template>
  <div v-if="!$store.state.pcshow"></div>
  <div class="bussinessCntainer" v-else>
    <div><img src="../assets/img/corporateNews/newsBg.jpg" class="topBg" /></div>
    <div class="newsCon">
      <div class="oneNews" @click="navNewsDetails">
        <img src="../assets/img/corporateNews/news.png" class="newsPic" />
        <div>
          <div class="title">我国医疗器械监管法规体系的构建、政策及发展建议</div>
          <div class="times">2021-06-09</div>
          <div class="cons">医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科学性提出了越来越高的要求。当前我国医疗健康产业发展迅速，公众对...</div>
        </div>
      </div>
      <div class="oneNews" @click="navNewsDetails">
        <img src="../assets/img/corporateNews/news.png" class="newsPic" />
        <div>
          <div class="title">我国医疗器械监管法规体系的构建、政策及发展建议</div>
          <div class="times">2021-06-09</div>
          <div class="cons">医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科学性提出了越来越高的要求。当前我国医疗健康产业发展迅速，公众对...</div>
        </div>
      </div>
      <div class="oneNews" @click="navNewsDetails">
        <img src="../assets/img/corporateNews/news.png" class="newsPic" />
        <div>
          <div class="title">我国医疗器械监管法规体系的构建、政策及发展建议</div>
          <div class="times">2021-06-09</div>
          <div class="cons">医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科学性提出了越来越高的要求。当前我国医疗健康产业发展迅速，公众对...</div>
        </div>
      </div>
      <div class="oneNews" @click="navNewsDetails">
        <img src="../assets/img/corporateNews/news.png" class="newsPic" />
        <div>
          <div class="title">我国医疗器械监管法规体系的构建、政策及发展建议</div>
          <div class="times">2021-06-09</div>
          <div class="cons">医疗器械产业属于知识密集型科技制造领域，产品研发与生产涉及机械、电子、高分子材料等多个专业，其复杂性、多样性对监管工作的规范性与科学性提出了越来越高的要求。当前我国医疗健康产业发展迅速，公众对...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
       
        
      }
    },
    created() {
      
    },
    methods: {
      navNewsDetails(){
        this.$router.push({name:'newsDetails',params:{id:1}}); 
      },
    }
  }
  

</script>

<style scoped>
  @media screen and (max-width: 960px){

  }

  @media screen and (min-width: 960px){
  .bussinessCntainer{
    width:100%;
  }
  .topBg{
    width:100%;
    height: 300px;
   overflow:hidden;
  }
  .newsCon{
    width: 1280px;
    background: #FFFFFF;
    margin:109px auto  130px auto;
  }
  .oneNews{
    position:relative;
width: 1280px;
height: 256px;
background: #FFFFFF;
border-radius: 6px;
display:flex;
align-items:center;
border-bottom:2px solid #EAEAEA;
  }
  .oneNews:hover{
    z-index:1;
    position:relative;
    cursor:pointer;
    padding-left:30px;
    margin-left:-30px;
    box-shadow: 0px 6px 18px 4px rgba(209, 209, 209, 0.65);
  }
  .line{
    width: 1220px;
height: 2px;
margin:o auto;
background: #EAEAEA;
  }
  .newsPic{
    width: 282px;
height: 198px;
overflow:hidden;
margin-right:30px;
  }
  .title{
    width: 282px;
    font-size: 18px;
font-family: Source Han Sans CN;
font-weight: 600;
color: #333333;
overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
  }
  .title:hover{
    color: #02B2B5;
    cursor:pointer;
  }
  .cons{
    width: 909px;
    margin-top:27px;
    font-size: 14px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #333333;
line-height:1.5;
text-overflow: -o-ellipsis-lastline;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;

  }
  .times{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999999;
margin-top:-10px;
text-align:right;
  }
  }
</style>
